import React, { useState, useEffect } from "react"
import { invokeApig } from "../../libs/awsLib";
import { validateTextNumber, validateTextNumberAndSpace } from '../../libs/validation';
import { Alert, Modal } from "react-bootstrap";

function Template(props) {
  const [template, setTemplate] = useState({ name: "", attributes: [] })
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    let id = (props.location.state && props.location.state.id) ? props.location.state.id : new URLSearchParams(props.location.search).get('id');
    if (id) {
      setIsLoading(true)
      async function getData() {
        const item = await getItem(id);
        setTemplate({
          id: item.payload.id,
          name: item.payload.name,
          attributes: item.payload.attributes
        });
        setIsLoading(false)
      }
      getData();
    }
  },[props.location.state, props.location.search]);

  const getItem = (id) => {
    return invokeApig({
      path: "/template/" + id,
      method: "GET"
    });
  }

  const save = (item) => {
    return invokeApig({
      path: "/template",
      method: "POST",
      body: item,
    });
  }

  const update = (item) => {
    return invokeApig({
      path: "/template",
      method: "PUT",
      body: item
    });
  }

  const deleteItem = (id) => {
    return invokeApig({
      path: "/template/" + id,
      method: "DELETE"
    });
  }

  const addNewAttributtes = () => {
    template.attributes.push({
      name: "",
      type: "",
      value: null,
      list: []
    })
    setTemplate({...template})
  }

  const removeAttributtes = (index) => {
    template.attributes.splice(index, 1);
    setTemplate({...template})
  };

  const addNewList = (index) => {
    template.attributes[index].list.push({
      key: "",
      value: "",
    })
    setTemplate({...template})
  }

  const removeList = (index, indexList) => {
    template.attributes[index].list.splice(indexList, 1);
    setTemplate({...template})
  };

  const onChangeTemplate = (event) => {
    template[event.target.name] = event.target.value
    setTemplate({...template})
  }

  const onChangeAtributtes = (index, event) => {
    template.attributes[index][event.target.name] = event.target.value
    const type = template.attributes[index].type
    if (type !== "select") {
      template.attributes[index].list.splice(0, template.attributes[index].list.length);
    }
    setTemplate({...template})
  }
  const onChangeList = (index, indexList, event) => {
    template.attributes[index].list[indexList][event.target.name] = event.target.value
    setTemplate({...template})
  }

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const getErrors = () => {
    let message = undefined;
    if (!validateTextNumberAndSpace(template.name, 2, 64)) {
      message = 'Debe ingresar el nombre correctamente: letras, números y espacios en blanco.';
    } else {
      const attrs = template.attributes;
      if (attrs.length === 0) {
        message = 'Debe ingresar atributos.';
      } else {
        for (let i = 0; i < attrs.length; i++) {
          if (!validateTextNumberAndSpace(attrs[i].name, 2, 64)) {
            message = 'Debes ingresar el nombre del atributo ' + (i + 1) + ': letras, números y espacios en blanco';
            break;
          } else {
            if (!attrs[i].type) {
              message = 'Debes seleccionar el tipo en el atributo ' + (i + 1);
              break;
            } else {
              if (attrs[i].type === 'select') {
                if (!attrs[i].list || attrs[i].list.length === 0) {
                  message = 'Debes ingresar valores en la lista del atributo ' + (i + 1);
                  break;
                }
                for (let j = 0; j < attrs[i].list.length; j++) {
                  const item = attrs[i].list[j];
                  if (!validateTextNumber(item.key, 2, 16)) {
                    message = 'Debes ingresar todas las claves en el atributo ' + (i + 1) + ': sólo letras y números';
                    break;
                  } else {
                    if (!validateTextNumberAndSpace(item.value, 2, 32)) {
                      message = 'Debes ingresar todos los valores en el atributo ' + (i + 1) + ': letras, números y espacios';
                      break;
                    }
                  }
                }
                if (message) {
                  break;
                }
              }
            }
          }
        }
      }
    }
    return message;
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    //validaciones
    setIsError(false);
    setMessage('');
    const message = getErrors();
    if (message) {
      setIsError(true);
      setMessage(message);
      return;
    }
    setIsLoading(true)
    const objects = convertArrayToObject(template.attributes, 'name');
    const claves = Object.keys(objects);
    const name = template.name;
    const request = {
      id: template.id,
      name: name,
      attributes: template.attributes,
      preview: { attributes: claves }
    };
    saveTemplate(request);
  }
  const saveTemplate = async (item) => {
    try {
      const response = item.id ? await update(item) : await save(item);
      if (response.code === 0) {
        setTimeout(props.history.push('/admin/alltemplate'), 2000);
      } else {
        setIsLoading(false)
        setIsError(true)
        setMessage(response.message)
        setShow(true)
      }
    } catch (e) {
      setIsLoading(false)
      setIsError(true)
      setMessage('Error al guardar template')
      setShow(true)
    }
  };

  const showConfirmDelete = () => {
    setShowDeleteModal(true)
  }

  const handleDeleteClose = () => {
    setShowDeleteModal(false)
  }

  const handleDeleteTemplate = async() => {
    try {
      setIsLoadingDelete(true)
      const response = await deleteItem(template.id);
      if (response.code === 0) {
        setTimeout(props.history.push('/admin/alltemplate'), 2000);
      } else {
        setIsLoadingDelete(false)
        setShowDeleteModal(false)
        setIsError(true)
        setMessage(response.message)
        setShow(true)
      }
    } catch (e) {
      setIsLoadingDelete(false)
      setShowDeleteModal(false)
      setIsError(true)
      setMessage('Error al eliminar template')
      setShow(true)
    }
  }

  const renderDelete = () => {
    return (
      <Modal
        show={showDeleteModal} onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
        >
          <Modal.Title>Template: {template.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Favor confirmar la Eliminación
        </Modal.Body>
        <Modal.Footer>
          {
            isLoadingDelete ?
            <>
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
            :
            <>
              <button className='btn btn-secondary mt-auto align-self-start rounded-0 w-100' onClick={handleDeleteClose}>Cancelar</button>
              <button className='btn btn-danger mt-auto align-self-start rounded-0 w-100' onClick={handleDeleteTemplate}>Eliminar</button>
            </>
          }
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="dynamic-form">
      <div className="template">
        <div className="container">
          <div className="">
            {
              !isLoading ?
                <div className="">
                  <form onSubmit={handleSubmit}>
                    <div>
                        <div className="pt-5 form-row justify-content-center">
                          <div className="form-group-admin col-md-12 mb-4">
                            <label>Nombre del template</label>
                            <input type="text" required={true} value={template.name} name="name" placeholder="Nombre" onChange={e => onChangeTemplate(e)} className="form-control" id="name" />
                          </div>
                        </div>
                        {template.attributes.map(((item, index) => (
                          <div key={index} >
                            <h5 className='text-left '>Atributo: {index + 1}</h5>
                            <div className="form-row justify-content-center align-items-center pt-2" >
                              <div className="form-group-admin col-md-3 mb-4">
                                <label>Nombre</label>
                                <input type="text" required={true} name="name" value={item.name} placeholder="Nombre" onChange={e => onChangeAtributtes(index, e)} className="form-control" id="name" />
                              </div>
                              <div className="form-group-admin col-md-4 mb-4">
                                <label>Type</label>
                                <select required={true} id="type" name="type" className="form-control" onChange={e => onChangeAtributtes(index, e)} value={item.type}>
                                  <option value="">Seleccione una opción</option>
                                  <option value="text">Texto</option>
                                  <option value="number">Numerico</option>
                                  <option value="select">Selección</option>
                                  <option value="color">Color</option>
                                  <option value="email">Correo</option>
                                  <option value="url">Link</option>
                                  <option value="tel">Teléfono</option>
                                  <option value="map">Mapa</option>
                                  <option value="video">Video</option>
                                </select>
                              </div>
                              <div className="form-group-admin col-md-2">
                                <button
                                  type="button"
                                  className="btn btn-danger mt-auto rounded-0 pt-1 pb-1 pl-2 pr-2"
                                  onClick={() => removeAttributtes(index)}
                                >
                                  x
                                </button>
                              </div>
                            </div>
                            {
                              item.type === "select" &&
                              <>
                                {
                                  item.list.map((itemList, indexList) => (
                                    <div key={indexList} className="form-row justify-content-center align-items-center" >
                                      <div className="form-group-admin col-md-4 mb-4">
                                        <label>Clave</label>
                                        <input type="text" required={true} name="key" value={itemList.key} placeholder="clave" onChange={e => onChangeList(index, indexList, e)} className="form-control" id="key" />
                                      </div>
                                      <div className="form-group-admin col-md-4 mb-4">
                                        <label>Valor</label>
                                        <input type="text" required={true} name="value" value={itemList.value} placeholder="clave" onChange={e => onChangeList(index, indexList, e)} className="form-control" id="value" />
                                      </div>
                                      <div className="form-group-admin col-md-4">
                                        <button
                                          type="button"
                                          className="btn btn-danger mt-auto rounded-0 pt-1 pb-1 pl-2 pr-2"
                                          onClick={() => removeList(index, indexList)}
                                        >
                                          x
                                        </button>
                                      </div>
                                    </div>
                                  ))
                                }
                                <div className="form-row justify-content-center align-items-center" >
                                  <div className="form-group-admin col-md-12 mb-4">
                                    <button
                                      className="btn btn-primary mt-auto align-self-start rounded-0 px-5 mb-5 mr-2"
                                      type="button"
                                      onClick={() => addNewList(index)}
                                    >
                                      Agregar
                                    </button>
                                  </div>
                                </div>
                              </>
                            }
                          </div>
                        )))}
                        {
                          show === true && <div className="alert alert-danger alert-dismissible fade show" role="alert">{message}
                            <button type="button" className="close" onClick={() => setShow(false)}>
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        }
                        <button className="btn btn-primary mt-auto align-self-start rounded-0 px-5 mb-5 mr-2" onClick={() => addNewAttributtes()}>
                          Añadir atributos
                        </button>
                        {
                          isError &&
                          <Alert variant='danger'>
                            {message}
                          </Alert>
                        }
                        {
                          template.attributes.length !== 0 &&
                          <div className="row">
                            <div className="col">
                              <button className="btn btn-success mt-auto align-self-start rounded-0 px-5 mb-5 mr-2" type="submit">
                                Guardar
                              </button>
                            </div>
                            {
                              template.id &&
                              <div className="col">
                                <button className="btn btn-danger mt-auto align-self-start rounded-0 px-5 mb-5 mr-2" type="button" onClick={showConfirmDelete}>
                                  Eliminar
                                </button>
                                {renderDelete()}
                              </div>
                            }
                          </div>
                        }
                      </div>
                  </form>
                </div>
                :
                <div className="pt-5 text-center">
                  <h2>Cargando, favor espere un momento...</h2>
                  <h2 className="mt-5">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </h2>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template
