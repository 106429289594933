import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
class Banner extends Component {
  state = {
    isOpen: false,
    display: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };
  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    return (
      <React.Fragment>
        <section id="" className="banner-area pb-5">
          <div className="banner-content">
            <div className="container">
              <div className="row no-gutters align-items-center">
                <div className="col-lg-12 col-md-12 align-items-end">
                  <Carousel showStatus={false} showArrows={false} showThumbs={false} showIndicators={false} infiniteLoop={true} emulateTouch={false} autoPlay={true}>
                    <div>
                      <h2>Compra y Venta de Bosques</h2>
                    </div>
                    <div>
                      <h2>Compra de Pino y Eucaliptus</h2>
                    </div>
                    <div>
                      <h2>Tasaciones</h2>
                    </div>
                    <div>
                      <h2>Asesoría Legal</h2>
                    </div>
                  </Carousel>
                  {/* <p>
                    <Link
                      className="btn btn-primary py-3 px-4"
                      to={{ pathname: "/catalog/list" }}
                    >
                      Ver todas las propiedades
                    </Link>
                  </p> */}
                </div>
                {/* <img src={require("../../images/002.jpg")} alt="error" /> */}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Banner;
