import React from 'react'
import { Link } from "react-router-dom";

class NotFound extends React.Component {
  static getInitialProps({ res, err }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return { statusCode };
  }

  render() {
    return (
      <React.Fragment>
        <section className="coming-soon-area">
          <div className="container">
            <div className="coming-soon-content">
              <img src={require("../images/logo.png")} alt="error" />
              <h1>Página no encontrada.</h1>
              <Link to="/" className="btn btn-primary">Ir al inicio</Link>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default NotFound;
