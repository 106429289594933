import React, { Component } from "react";
import ItemList from './ItemList';

export default class ListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
  render() {
    const { items } = this.props;
    return (
      <section className="ListItems">
        <div>
          {
            items.map((item, index) => {
              return (
                <div key={index}>
                  <ItemList item={item} />
                </div>
              );
            })
          }
        </div>
      </section>
    );
  }
}
