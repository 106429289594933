import React, { Component } from 'react';
import Banner from './Banner';

class About extends Component {
  render() {
    return (
      <section id="about" className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="default-section-title pb-5">
                <h2>Nosotros</h2>
                <div className="bar"></div>
              </div>
              <Banner />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="about-content">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-inner-content">
                      <div className="title">
                        <div className="icon">
                          <i className="fas fa-business-time"></i>
                        </div>
                        <h3>Experiencia</h3>
                      </div>
                      <p>Somos un equipo de profesionales que trabaja en el rubro forestal hace más de 30 años.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-inner-content">
                      <div className="title">
                        <div className="icon">
                        <i className="fas fa-lock"></i>
                        </div>
                        <h3>Seguridad</h3>
                      </div>
                      <p>Contamos con un prestigio y una conducta intachable en nuestros años de operación.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-inner-content">
                      <div className="title">
                        <div className="icon">
                          <i className="fas fa-wifi"></i>
                        </div>
                        <h3>Innovación</h3>
                      </div>
                      <p>Nuestra oferta digital de servicios, facilitará la operación con nuestros clientes.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-inner-content">
                      <div className="title">
                        <div className="icon">
                          <i className="fas fa-sync"></i>
                        </div>
                        <h3>Flexibilidad</h3>
                      </div>
                      <p>Nos adaptamos y trabajamos en las distintas necesidades de nuestros clientes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
