import React, { Component } from 'react';
import AdminSidebar from '../../containers/admin/Layout/AdminSidebar';
import TopBar from '../../containers/admin/Layout/TopBar';
import Template from '../../containers/admin/Template';

export default class EditTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: "Template",
      style: "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    };
  }

  changeStyle = (evt) => {
    if (this.state.style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      this.setState({ style: "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" });
    }
    else {
      this.setState({ style: "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" });
    }
  }
  changeStyle1 = (evt) => {
    if (this.state.style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      this.setState({ style: "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1" });
    }
    else {
      this.setState({ style: "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div id="page-top">
          <div id="wrapper">
            <AdminSidebar style={this.state.style} changeStyle={this.changeStyle} />
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <TopBar style={this.state.style} dashboard={this.state.dashboard} changeStyle1={this.changeStyle1} />
                <div className="container-fluid">
                  <Template {...this.props}/>
                </div>
              </div>
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>Copyright &copy; Neokode 2023</span>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};
