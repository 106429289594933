import React, { Component } from "react";
import "../Home/About.css";
import FormContact from "./FormContact";
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: '',
      phone: '',
      email: '',
      message: '',
      alertMessage: '',
      isErrorMessage: false
    };
  }
  render() {
    return (
      <section id="contact" className="contact ptb-5">
        <div className="container mt-5">
        <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 default-section-title">
              <h2>
                Contáctanos
              </h2>
              <div className="bar"></div>
              <p className="mb-5 ">
                Puedes contactarnos por nuestros diferentes medios de comunicación
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
            <FormContact/>
            </div>
            <div className="col-lg-6 mt-4">
            <img src={require("../../images/contact.jpg")} alt="contact" />
            </div>
          </div>
        </div>
      </section>
    );
  }
};