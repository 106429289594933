import React, { Component } from "react";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { invokeApig } from "../../libs/awsLib";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

export default class ListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      items: [],
      lastId: null,
      isMoreLoading: false,
      refresh: this.props.refresh,
      rowId: {},
      status: ""
    };
  }
  componentDidMount() {
    this.items();
  }
  componentWillReceiveProps(props) {
    if (props.refresh !== this.state.refresh) {
      this.items();
      this.setState({ refresh: props.refresh });
    }
  }
  items = async (request) => {
    this.setState({ isLoading: true, items: [] });
    try {
      const response = await this.getItems(request || { limit: 100 });
      this.setState({ items: response.items, lastId: response.lastId, status: response.status });
    } catch (e) {
      this.setState({ isError: true });
    }
    this.setState({ isLoading: false });
  };
  getItems(request) {
    return invokeApig({
      path: "/catalog/find",
      method: "POST",
      body: request,
    });
  }
  handleMore = async () => {
    this.setState({ isMoreLoading: true });
    try {
      const response = await this.getItems({ lastId: this.state.lastId });
      this.setState({
        items: [...this.state.items, ...response.items],
        lastId: response.lastId,
        isMoreLoading: false,
      });
    } catch (e) {
      this.setState({ isError: true, isMoreLoading: false });
    }
  };
  setTableOption() {
    if (this.state.isLoading) {
      return (
        <div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>
      );
    } else {
      return this.state.isError
        ? "Error al obtener los usuarios. Intenta más tarde."
        : "No hay datos";
    }
  }
  actionsFormatter = (cell, row) => {
    if (row.id === this.state.rowId.id) {
      return (
        <Link
          to={{ pathname: "/admin/detail", search: "?id=" + row.id, state: row }}
        >
          ir
        </Link>
      );

    }
    return (
      <span>{cell}</span>
    );
  };
  dateFormatter = (cell, row) => {
    const date = moment(cell);
    return date.format("DD-MM-YYYY HH:mm (UTC)");
  };
  render() {
    const rowStyle = { cursor: 'pointer' };
    const data = this.state.items;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "id",
        isKey: true,
        text: "Identificador",
        hidden: true,
        formatter: (cellContent, row) => {
          return (
            <Link
              to={{ pathname: "/admin/editproduct", search: "?id=" + row.id }}
            >
              {row.id}
            </Link>
          );
        }
      },
      {
        dataField: "template",
        text: "Template",
        sort: true,
        csvFormatter: (cell, row, rowIndex) => row.template.name,
        formatter: (cellContent, row) => {
          return row.template.name;
        }
      },
      {
        dataField: "code",
        text: "Código",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <Link
              to={{ pathname: "/admin/editproduct", search: "?id=" + row.id }}
            >
              {row.code}
            </Link>
          );
        }
      },
      {
        dataField: "title",
        text: "Título",
        sort: true,
      },
      {
        dataField: "featured",
        text: "Destacado",
        sort: true,
        csvFormatter: (cell, row, rowIndex) => row.featured ? 'Si' : 'No',
        formatter: (cellContent, row) => {
          if (row.featured) {
            return (
              <h5 className="text-center">
                <span className="badge badge-pill badge-success"> <i className="fas fa-check"></i> Si</span>
              </h5>
            );
          }
          return (
            <h5 className="text-center">
              <span className="badge badge-pill badge-danger"><i className="fas fa-times"></i> No</span>
            </h5>
          );
        }
      },
      {
        dataField: "creation_date",
        text: "Fecha de creación",
        sort: true,
        formatter: (cell) => {
          const date = moment(cell);
          return date.format("DD-MM-YYYY HH:mm (UTC)");
        },
      },
      {
        dataField: "business_name",
        text: "Razón Social",
        sort: true,
      },
      {
        dataField: "customer_name",
        text: "Cliente",
        sort: true,
      },
      {
        dataField: "status",
        text: "Estado",
      },
      {
        dataField: "long_desc",
        text: "Descripción",
        hidden: true,
        csvFormatter: (cell, row, rowIndex) => row.long_desc,
      },
      {
        dataField: "attributes",
        text: "Atributos",
        hidden: true,
        csvFormatter: (cell, row, rowIndex) => row.attributes.map((attr) => `${attr.name}: ${attr.value}`),
      }
    ];
    const { ExportCSVButton } = CSVExport;
    return (
      <div className="admin-table px-5 py-2 mx-auto">
        {/* <BootstrapTable data={ data } selectRow={ selectRowProp } options={tableOptions}  trClassName>
              <TableHeaderColumn isKey dataField='id' filter={ { type: 'TextFilter', placeholder: 'Ingresa un código', delay: 10 } } dataSort={ true } dataFormat={ this.actionsFormatter }>Código</TableHeaderColumn>
              <TableHeaderColumn dataField='type' filter={ { type: 'TextFilter', placeholder: 'Ingresa un tipo', delay: 10 } } dataSort={ true } dataFormat={ this.actionsFormatter }>Categoría</TableHeaderColumn>
              <TableHeaderColumn dataField='title' filter={ { type: 'TextFilter', placeholder: 'Ingresa un título', delay: 10 } } dataSort={ true } dataFormat={ this.actionsFormatter }>Título</TableHeaderColumn>
              <TableHeaderColumn dataField='status' filter={ { type: 'TextFilter', placeholder: 'Ingresa un estado', delay: 10 } } dataSort={ true }>Estado</TableHeaderColumn>
              <TableHeaderColumn dataField='creation_date' dataFormat={ this.dateFormatter } dataSort={ true }>Fecha de creación</TableHeaderColumn>
            </BootstrapTable> */}
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV
        >
          {
            props => (
              <div>
                <Row>
                  <Col md={1} style={{margin: 'auto 0'}}><h3>Filtro: </h3></Col>
                  <Col><SearchBar {...props.searchProps} /></Col>
                  <Col md={3}><ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></Col>
                </Row>
                <hr />
                <BootstrapTable classes="table-responsive-md" {...props.baseProps} keyField="id" rowStyle={rowStyle} noDataIndication={this.state.isLoading ?
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div> : data.length === 0 ? <h3>No hay datos que mostrar</h3> : <></>
                } />
                {this.state.lastId &&
                  (this.state.isMoreLoading ? (
                    <div className="">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={this.handleMore}
                        className="btn btn-primary mt-auto align-self-start rounded-0"
                      >
                        Cargar más...
                      </button>
                    </div>
                  ))}
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }
}
