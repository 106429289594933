import React, { Component } from "react";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser
} from "amazon-cognito-identity-js";
import { Modal } from "react-bootstrap";
import config from "../../config";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertError: false,
      alertMessage: '',
      email: "",
      phone_number: "",
      password: "",
      newPassword: "",
      newPasswordRep: "",
      isChangePassword: false,
      user: null,
      showtyc: true
    };
  }

  showNewPasswordForm(userAttributes, user) {
    this.setState({ ...userAttributes, isChangePassword: true, user: user, isLoading: false });
    return Promise.resolve()
  }

  login(email, password) {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID
    });
    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: result => resolve(),
        onFailure: err => reject(err),
        newPasswordRequired: (userAttributes, requiredAttributes) => this.showNewPasswordForm(userAttributes, user)
      })
    );
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true, showtyc: true });
    try {
      await this.login(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      let message = 'Error al iniciar sesión';
      if (e.code === 'UserNotFoundException') {
        message = 'El email ingresado no está registrado';
      } else {
        if (e.code === 'NotAuthorizedException') {
          message = 'Clave incorrecta, intenta nuevamente.';
        }
      }
      this.handleAlertError(message, { isLoading: false });
    }
  }

  changePassword(newPassword, name, family_name, phone_number) {
    const userAttributes = {
      name: name,
      family_name: family_name,
      phone_number: phone_number
    }
    return new Promise((resolve, reject) =>
      this.state.user.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: result => resolve(),
        onFailure: err => reject(err)
      })
    );
  }

  handleSubmitChangePassword = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      if (this.state.newPassword !== this.state.newPasswordRep) {
        this.handleAlertError('Las claves no coinciden. Debes ingresar la misma clave');
      } else {
        await this.changePassword(this.state.newPassword, this.state.name, this.state.family_name, this.state.phone_number);
        this.props.userHasAuthenticated(true);
      }
      this.setState({ isLoading: false });
    } catch (e) {
      let message = 'Error al modificar la clave. debe contener al menos ocho carácteres, un número, un carácter especial, una letra mayúscula y una letra minúscula';
      this.handleAlertError(message, { isLoading: false });
    }
  }

  handleAlertError = (message, extras) => {
    this.setState({ ...extras, alertMessage: message, showAlertError: true });
  }

  handleDismissError = () => {
    this.setState({ showAlertError: false });
  }

  handleCloseClickTyC = () => {
    this.setState({ showtyc: false, isChangePassword: false, email: "", password: "" });
  }

  handleClickTyC = () => {
    this.setState({ showtyc: false });
  }

  render() {
    return (
      this.state.isChangePassword ?
        <div className="Login">
          <form className="card-body cardbody-color p-lg-2" onSubmit={this.handleSubmitChangePassword}>
            <div className="text-center">
              <h3 className="mb-4">Cambio de contraseña</h3>
            </div>
            <div className="mb-3 text-left">
              <label>Nombre</label>
              <input type="text" value={this.state.name} onChange={this.handleChange} placeholder="Sólo texto" className="form-control" id="name" />
            </div>
            <div className="mb-3 text-left">
              <label>Apellido</label>
              <input type="text" value={this.state.family_name} onChange={this.handleChange} placeholder="Sólo texto" id="family_name" className="form-control" />
            </div>
            <div className="mb-3 text-left">
              <label>Teléfono</label>
              <input type="text" value={this.state.phone_number} onChange={this.handleChange} placeholder="+1234567890" id="phone_number" className="form-control" />
            </div>
            <div className="mb-3 text-left">
              <label>Nueva Clave</label>
              <input type="password" value={this.state.newPassword} onChange={this.handleChange} placeholder="Letras y números" id="newPassword" className="form-control" />
            </div>
            <div className="mb-3 text-left">
              <label>Confirmar Nueva Clave</label>
              <input tvalue={this.state.newPasswordRep} onChange={this.handleChange} type="password" placeholder="Letras y números" id="newPasswordRep" className="form-control" />
            </div>
            <div className="text-center">
              {
                !this.state.isLoading ? <button type="submit" className="btn btn-primary mt-auto align-self-start rounded-0 px-5 mb-5 w-100" disabled={!this.validateForm()}>Cambiar clave</button> : <button className="btn btn-primary mt-auto align-self-start rounded-0 px-5 mb-5 w-100" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Cambiando clave
                </button>
              }
            </div>
            {
              this.state.showAlertError &&
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{this.state.alertMessage}</strong>
                <button type="button" onClick={this.handleDismissError} className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            }
          </form>
          <Modal show={this.state.showtyc} onHide={() => { return false; }}>
            <Modal.Header>
              <Modal.Title>Términos y condiciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Pendiente!
            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-danger mt-auto align-self-start rounded-0 w-100' onClick={this.handleCloseClickTyC}>No acepto</button>
              <button className='btn btn-success mt-auto align-self-start rounded-0 w-100' onClick={this.handleClickTyC}>Acepto</button>
            </Modal.Footer>
          </Modal>
        </div>
        :
        <div className="Login">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <img src={require("../../images/logo.png")} className="img-fluid profile-image-pic mb-4 mt-5"
                  width="200px" alt="profile" />
                <form className="card cardbody-color p-3" onSubmit={this.handleSubmit}>
                  <div className="text-center">
                    <h3 className="mb-4">Inicia sesión</h3>
                  </div>
                  <div className="mb-3 text-left">
                    <label>Email</label>
                    <input type="email" value={this.state.email} className="form-control" onChange={this.handleChange} id="email" placeholder="Ingresar email" aria-describedby="emailHelp" />
                  </div>
                  <div className="mb-3 text-left">
                    <label>Clave</label>
                    <input type="password" value={this.state.password} className="form-control" onChange={this.handleChange} id="password" placeholder="Ingresar clave" />
                  </div>
                  {
                    this.state.showAlertError &&
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                      <strong>{this.state.alertMessage}</strong>
                      <button type="button" onClick={this.handleDismissError} className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  }
                  <div className="text-center">
                    {
                      !this.state.isLoading ? <button type="submit" className="btn btn-primary mt-auto align-self-start rounded-0 px-5 mb-5 w-100" disabled={!this.validateForm()}>Ingresar</button> : <button className="btn btn-primary mt-auto align-self-start rounded-0 px-5 mb-5 w-100" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Iniciando sesión
                      </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
