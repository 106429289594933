import React, { Component } from "react";
import CKEditor from "react-ckeditor-component";
import Select from "react-select";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { invokeApig, s3Upload, s3getObject } from '../../libs/awsLib';
import "./NewItem.css";
import { Col, Modal, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

export default class NewProduct extends Component {
  constructor(props) {
    super(props);
    this.onDrop = (file) => {
      this.setState({ file })
    };
    this.state = {
      show: false,
      renderNew: true,
      template: {},
      isSelectedTemplate: false,
      id: "",
      code: "",
      title: "",
      type: "",
      customerName: "",
      businessName: "",
      long_desc: "",
      featured: false,
      ismap: false,
      status: "",
      alertMessage: "",
      attributes: {},
      file: [],
      files: [],
      isImageLoading: [],
      defaultImages: [],
      isUpdateImages: false,
      isImageLoadingButton: false,
      imagesLength: 1,
      isLoading: false,
      isLoadingInitial: false,
      isWithOutImages: false,
      isLoadingUpdate: false,
      isErrorMessage: false,
      templates: [],
      redirect: false,
      row: {},
      new: {},
      showDeleteModal: false,
      isLoadingDelete: false,
      showVideoInfo: false,
      showMapInfo: false,
    };
  }

  async componentDidMount() {
    const templates = await this.templates();
    let id = (this.props.location.state && this.props.location.state.id) ? this.props.location.state.id : new URLSearchParams(this.props.location.search).get('id');
    if (id) {
      this.setState({isLoading: true});
      const item = await this.getItem(id);
      item.payload.defaultImages = item.payload.images ? item.payload.images.map((image) => '/images/catalog/' + item.payload.id + '/' + image) : [];
      let template = {value: item.payload.template.id, label: item.payload.template.name};
      templates.map((t) => {
        if (t.template.id === item.payload.template.id) {
          template.template = t.template;
        }
        return false;
      });
      this.setState({...item.payload, template: template, isSelectedTemplate: true, isLoading: false, renderNew: false});
    } else {
      this.setState({id: uuidv4().replaceAll('-', '')})
    }
  }

  getTemplates() {
    return invokeApig({
      path: "/catalog/template/find",
      method: "POST",
      body: {},
    });
  }

  getItem(id) {
    return invokeApig({
      path: "/catalog/" + id,
      method: "GET"
    });
  }

  save(item) {
    return invokeApig({
      path: "/catalog",
      method: "POST",
      body: item,
    });
  }

  update(item, isNewImages) {
    return invokeApig({
      path: "/catalog",
      method: "PUT",
      body: {
        isItem: true,
        isNewImages,
        item
      }
    });
  }

  deleteItem(id) {
    return invokeApig({
      path: "/catalog/" + id,
      method: "DELETE"
    });
  }

  templates = async () => {
    this.setState({ isLoading: true, isLoadingInitial: true });
    try {
      const response = await this.getTemplates();
      var templates = [];
      response.items.map((template, index) => {
        templates.push({
          value: template.id,
          label: template.name,
          template: template,
        });
        return null;
      });
      this.setState({ isLoading: false, isLoadingInitial: false, templates: templates });
      return templates;
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, isLoadingInitial: false, isError: true, message: 'Error al cargar templates' });
    }
    this.setState({ isLoading: false, isLoadingInitial: false });
    return [];
  };

  handleOnDrop = (pictureFiles, pictureDataURLs) => {
    this.setState({
      defaultImages: pictureDataURLs,
      isUpdateImages: true
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleChangeFile = (e) => {
    this.setState({ file: e });
  };

  handleChangeAttr = (element, event) => {
    const { attributes } = this.state;
    element.value = event.target.value;
    this.setState({ attributes: attributes });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true, isError: false, isErrorMessage: '' });
    if (this.state.isUpdateImages) {
      if (this.state.defaultImages.length > 0) {
        await this.generateFileImages();
      } else {
        this.setState({ isWithOutImages: true, isLoadingUpdate: false });
      }
    } else {
      await this.saveItem({ isImages: false });
    }
  };

  generateFileImages = event => {
    const picturesPromises = [];
    const picturesPromisesIndexs = [];
    let pictures = this.state.defaultImages.map((dataurl, index) => {
      if (dataurl.search('/images/catalog/') === -1) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], 'image_' + index, { type: mime });
      } else {
        let file = s3getObject(dataurl.substring(1));
        picturesPromises.push(file);
        picturesPromisesIndexs.push(index);
        return file;
      }
    });
    Promise.all(picturesPromises).then(newPictures => {
      newPictures.forEach((picture, index) => {
        var file = new File([picture.Body], { type: picture.ContentType });
        pictures[picturesPromisesIndexs[index]] = file;
      });
      this.compressImages(pictures);
    });
  }

  compressImages = pictures => {
    const resizePromises = pictures.map(picture => {
      return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(picture, 800, 800, 'JPEG', 80, 0, compressImage => {
          resolve(compressImage)
        }, 'blob');
      })
    })

    Promise.all(resizePromises).then(rezizeImages => {
      rezizeImages.forEach((image, index) => {
        pictures[index] = image;
      });
      this.setState({ pictures: pictures })
      this.handleSubmitFiles();
    });
  }

  handleSubmitFiles = async (event) => {
    const pictures = this.state.pictures.slice();
    const images = {
      isImages: true,
      images: []
    };
    if (pictures) {
      const folder = 'images/catalog';
      let previe_name = this.state.id + '_preview.jpg';
      await s3Upload(pictures[0], { folder: folder, name: previe_name });
      images['preview'] = { image: previe_name }
      await Promise.all(pictures.map(async (file, index) => {
        const name = this.state.id + '-' + index + '.jpg';
        await s3Upload(file, { folder: 'images/catalog/' + this.state.id, name: name });
        images.images.push(name);
      }));
    }
    await this.saveItem(images);
  }

  saveItem = async (data) => {
    try {
      const { id, code, title, type, long_desc, featured, status, creation_date, attributes, images, businessName, customerName, template } = this.state;
      const item = {
        id: id,
        code: code,
        title: title,
        type: type,
        customer_name: customerName,
        business_name: businessName,
        long_desc: long_desc,
        creation_date: creation_date,
        featured: featured,
        status: status,
        attributes: attributes,
        images: data.isImages ? data.images : images,
        preview: data.isImages ? Object.assign(data.preview, this.state.template.template.preview) : this.state.template.template.preview,
        template: { id: template.value, name: template.label }
      }
      const response = item.id ? await this.update(item) : await this.save(item);
      if (response.code === 0) {
        this.setState({ isLoading: false, code: response.code, row: item, isErrorMessage: false, isError: false, alertMessage: 'Producto guardado correctamente.' });
        if (response) {
          setTimeout(this.props.history.push('/admin/allproduct'), 2000);
        }
      }
      else {
        this.setState({ isUpdateImages: false, isLoading: false, isErrorMessage: true, isError: true, alertMessage: response.message })
      }
    } catch (e) {
      console.log(e);
      this.setState({ isUpdateImages: false, isLoading: false, isErrorMessage: true, isError: true, alertMessage: 'Problemas al guardar producto.' });
    }
  }

  handleChangeTemplate = (template) => {
    this.setState({
      template: template,
      attributes: template.template.attributes,
      isSelectedTemplate: true,
    });
  };
  handleChangeFeatured = (evt) => {
    this.setState({ featured: evt.target.checked });
  };

  handleChangeHtml = (event) => {
    var newContent = event.editor.getData();
    this.setState({ 'long_desc': newContent });
  }

  handleClick = (evt) => {
    evt.preventDefault();
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  nameLengthValidator(file) {
    const maxLength = 20;
    if (file.name.length > maxLength) {
      return {
        code: "name-too-large",
        message: `El nombre tiene más de ${maxLength} caracteres`
      };
    }
    return null
  }

  removeFile = (index) => {
    const lists = this.state.file.splice(index, 1);
    this.setState({ lists });
  };

  handleDeleteClose = () => {
    this.setState({showDeleteModal: false})
  }

  handleDeleteItem = async () => {
    try {
      this.setState({ isLoadingDelete: true, isError: false, isErrorMessage: '' });
      const response = await this.deleteItem(this.state.id);
      if (response.code === 0) {
        setTimeout(this.props.history.push('/admin/allproduct'), 2000);
      } else {
        this.setState({ isLoadingDelete: false, isError: true, isErrorMessage: response.message });
      }
    } catch (e) {
      console.log(e);
      this.setState({ isLoadingDelete: false, isError: true, isErrorMessage: 'Error al eliminar ítem' });
    }
  }

  renderDelete() {
    return (
      <Modal
        show={this.state.showDeleteModal} onHide={this.handleDeleteClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Producto: {this.state.title} (Cód: {this.state.code})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Favor confirmar la Eliminación
        </Modal.Body>
        <Modal.Footer>
          {
            this.state.isLoadingDelete ?
            <>
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
            :
            <>
              <button className='btn btn-secondary mt-auto align-self-start rounded-0 w-100' onClick={this.handleDeleteClose}>Cancelar</button>
              <button className='btn btn-danger mt-auto align-self-start rounded-0 w-100' onClick={this.handleDeleteItem}>Eliminar</button>
            </>
          }
        </Modal.Footer>
      </Modal>
    );
  }

  handleShowMapInfo = (evt) => {
    evt && evt.preventDefault();
    this.setState({showMapInfo: true});
  }
  handleMapInfoClose = (evt) => {
    evt && evt.preventDefault();
    this.setState({showMapInfo: false});
  }
  handleShowVideoInfo = (evt) => {
    evt && evt.preventDefault();
    this.setState({showVideoInfo: true});
  }
  handleVideoInfoClose = (evt) => {
    evt && evt.preventDefault();
    this.setState({showVideoInfo: false});
  }

  showConfirmDelete = () => {
    this.setState({showDeleteModal: true});
  }

  renderVideoInfo() {
    return (
      <Modal
        show={this.state.showVideoInfo} onHide={this.handleVideoInfoClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title>¿Cómo obtener el código de un video?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-info-video">
          <h5 className="text-center">
            Para obtener el código de un campo de video debes seguir los siguientes pasos:
          </h5>
          <ul>
            <li>Localiza tu video en <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">Youtube</a></li>
            <li><img src={require("../../images/video-1.png")} height="300" alt="Video" /></li>
            <li>Selecciona la opción <b>Compartir</b></li>
            <li><img src={require("../../images/video-2.png")} height="300" alt="Video" /></li>
            <li>Haz click en <b>Incorporar</b></li>
            <li><img src={require("../../images/video-3.png")} height="300" alt="Video" /></li>
            <li>Haz click en <b>Copiar</b></li>
            <li>Finalmente pega el código copiado en el campo de video</li>
            <li><img src={require("../../images/video-4.png")} height="100" alt="Video" /></li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary mt-auto align-self-start rounded-0 w-100' onClick={this.handleVideoInfoClose}>Cerrar</button>
        </Modal.Footer>
      </Modal>
    );
  }
  renderMapInfo() {
    return (
      <Modal
        show={this.state.showMapInfo} onHide={this.handleMapInfoClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title>¿Cómo obtener el código de un mapa?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-info-map">
          <h5 className="text-center">
            Para obtener el código de un campo de mapa debes seguir los siguientes pasos:
          </h5>
          <ul>
            <li>Entra a <a href="https://maps.google.com" target="_blank" rel="noopener noreferrer">Google Maps</a> y busca lo que quieres mostrar, por ejemplo un punto exacto o una localidad</li>
            <li><img src={require("../../images/map-1.png")} height="400" alt="Mapa" /></li>
            <li>Selecciona la opción <b>Compartir</b></li>
            <li><img src={require("../../images/map-2.png")} height="300" alt="Mapa" /></li>
            <li>Selecciona la opción <b>Insertar un mapa</b> y luego <b>Copiar HTML</b></li>
            <li>Finalmente pega el código copiado en el campo de mapa</li>
            <li><img src={require("../../images/map-3.png")} height="100" alt="Mapa" /></li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary mt-auto align-self-start rounded-0 w-100' onClick={this.handleMapInfoClose}>Cerrar</button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderFormNew() {
    const config = {
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        {
          name: "editing",
          groups: ["find", "selection", "spellchecker", "editing"]
        },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
          name: "paragraph",
          groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
        },
        "/",
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        "/",
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] }
      ],
      removeButtons:
        "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
      fontSize_sizes: "16/16px;24/24px;48/48px;",
      font_names:
        "Arial/Arial, Helvetica, sans-serif;" +
        "Times New Roman/Times New Roman, Times, serif;" +
        "Verdana",
      allowedContent: true
      // disableNativeSpellChecker: false
      // skin: "moono",
      // plugins:
      //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
    };

    return (
      <section className="ItemDetail">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-lg-8">
              <div className="form-row">
                <div className="form-group-admin col-md-12 mb-4">
                  <Select value={this.state.template} onChange={this.handleChangeTemplate} options={this.state.templates} />
                </div>
              </div>
              {
                this.state.isSelectedTemplate ?
                  <div>
                    <div className="card shadow-sm mb-4">
                      <div className="card-header text-left p-2">
                        <label className="m-0">Datos del producto</label>
                      </div>
                      <div className="card-body p-3">
                        <div className="form-row">
                          <div className="form-group-admin col-md-6 mb-4">
                            <label>Código</label>
                            <input type="text" required={true} onChange={this.handleChange} value={this.state.code} placeholder="Código" className="form-control" id="code" />
                          </div>
                          <div className="form-group-admin col-md-6 mb-4">
                            <label>Título</label>
                            <input onChange={this.handleChange} required={true} value={this.state.title} placeholder="Título" type="text" className="form-control" id="title" />
                          </div>
                        </div>
                        <div className="form-row">
                          {/** <div className="form-group-admin col-md-6 mb-4">
                            <label >Categoría</label>
                            <select id="type" className="form-control" required={true} onChange={this.handleChange} value={this.state.type}>
                              
                            </select>
                          </div> */}
                          <div className="form-group-admin col-md-6 mb-4 pl-3">
                            <label >Estado</label>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="status" id="status" value="Activo" checked={this.state.status === "Activo"}
                                onChange={this.handleChange} />
                              <label className="form-check-label">
                                Activo
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="status" id="status" value="Inactivo" checked={this.state.status === "Inactivo"}
                                onChange={this.handleChange} />
                              <label className="form-check-label">
                                Inactivo
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group-admin mb-4">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="featured" onChange={this.handleChangeFeatured} />
                            <label className="form-check-label" onChange={this.handleChangeFeatured} >
                              &iquest;Destacado?
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                      <div className="card-header text-left p-2">
                        <label className="m-0">Información interna del cliente</label>
                      </div>
                      <div className="card-body p-3">
                        <div className="form-row">
                          <div className="form-group-admin col-md-6 mb-4">
                            <label>Nombre</label>
                            <input type="text" required={false} onChange={this.handleChange} value={this.state.customerName} placeholder="Nombre" className="form-control" id="customerName" />
                          </div>
                          <div className="form-group-admin col-md-6 mb-4">
                            <label>Razón social</label>
                            <input onChange={this.handleChange} required={false} value={this.state.businessName} placeholder="Razón social" type="text" className="form-control" id="businessName" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                      <div className="card-header text-left p-2">
                        <label className="m-0">Descripción del producto</label>
                      </div>
                      <div className="card-body p-1">
                        <div className="form-group-admin">
                          <CKEditor
                            id="long_desc"
                            config={config}
                            activeclassName="p10"
                            content={this.state.long_desc}
                            events={{
                              "required": true,
                              "change": this.handleChangeHtml
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                      <div className="card-header text-left p-2">
                        <label className="m-0">Características del producto</label>
                      </div>
                      <div className="card-body p-3">
                        <div className="form-row">
                        {
                          this.state.attributes.map((value, index) => {
                            return (
                              <div key={index} className="form-group-admin col-md-6 mb-4">
                                <label>{value.name}</label>
                                {value.type === "map" || value.type === "video" ? <button type="button" className="badge badge-light btn-modal-info" onClick={value.type === "map" ? this.handleShowMapInfo : this.handleShowVideoInfo}>¿Cómo obtener este dato?</button> : ''}
                                {
                                  value.type === "select" ?
                                  <select required={true} className="form-control" onChange={(evt) => this.handleChangeAttr(value, evt)} value={value.value ? value.value : ""}>
                                    <option value="">Seleccione un valor</option>
                                    {value.list.map((x, indexSel) => {
                                      return (
                                        <option key={indexSel} value={x.key} disabled={false}>
                                          {x.value}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  :
                                  value.type === "map" || value.type === "video" ?
                                    <input required={false} className="form-control" type="text" onChange={(evt) => this.handleChangeAttr(value, evt)}
                                      value={value.value ? value.value : ""}
                                      placeholder={value.label} />
                                    :
                                    <input required={true} className="form-control" type={value.type} onChange={(evt) => this.handleChangeAttr(value, evt)}
                                      value={value.value ? value.value : ""}
                                      placeholder={value.label} />}
                              </div>
                            );
                          })
                        }
                        </div>
                      </div>
                    </div>
                  </div> : <div className="alert alert-primary" role="alert"> Debe seleccionar un template para continuar</div>
              }
            </div>
            {
              this.state.isSelectedTemplate &&
              <div className="col-lg-4">
                <div className="card shadow-sm mb-4">
                  <div className="card-header text-left p-2">
                    <label className="m-0">Imagen del producto</label>
                  </div>
                  <div className="card-body p-3">
                    <div id="images">
                      <ImageUploader
                        withIcon={false}
                        buttonText='Seleccione imágenes'
                        onChange={this.handleOnDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={31457280}
                        withPreview={true}
                        label="Agrege o elimine imagenes"
                        withLabel={false}
                        singleImage={false}
                        defaultImages={this.state.defaultImages}
                      />
                    </div>
                    <div className="alert alert-info">
                      <b>Nota:</b> El tamaño máximo permitido es de <b>30 Mb</b>
                    </div>
                  </div>
                </div>
                {this.state.alertMessage && this.state.isErrorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.alertMessage}
                  </div>
                )}
                {
                  (this.state.isUpdateImages || !this.state.renderNew) &&
                  <Row>
                    <Col>
                      <button type="submit" className="btn btn-primary rounded-0" >Guardar</button>
                    </Col>
                    {
                      !this.state.renderNew &&
                      <Col>
                        <button type="button" onClick={this.showConfirmDelete} className="btn btn-danger rounded-0">Eliminar</button>
                        { this.renderDelete() }
                      </Col>
                    }
                  </Row>
                }
              </div>
            }
          </div>
        </form>
        {this.renderMapInfo()}
        {this.renderVideoInfo()}
      </section>
    );
  }

  render() {
    return (
      <div className="NewItem">
        <div className="row">
          <div className="col-md-12">
            {
              !this.state.isLoading ? <div className="container-fluid px-5 py-2 mx-auto">
                <h4>Seleccione un template de producto</h4>
                {this.renderFormNew()}
              </div>
                :
                this.state.isLoadingInitial ?
                  <div className="pt-5 text-center">
                    <h2>Cargando datos, favor espere un momento...</h2>
                    <h2 className="mt-5"><div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div></h2>
                  </div> : <div className="pt-5 text-center">
                    <h2>Creando el producto, favor espere un momento...</h2>
                    <h2 className="mt-5"><div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div></h2>
                  </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
