import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ItemGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      item: this.props.item,
    };
  }
  render() {
    const { item } = this.state;
    return (
      <section className="ItemGrid">
        <div className="card">
          {/* <img src={`https://portalcorretajesspa.cl/images/catalog/${item.preview.image}`} className="item-img-grid card-img-top" alt="Card Image"/> */}
          <div
            className="item-img-grid card-img-top"
            style={{
              backgroundImage: `url(/images/catalog/${item.preview.image})`,
            }}
          >
            {/* <div className="label">{item.attributes.type.value}</div> */}
          </div>
          <div className="card-body d-flex flex-column">
            <h3>{item.title}</h3>
            <span className="mb-3"><b>Código:</b> {item.code}</span>
            <Link
              className="btn btn-primary mt-auto align-self-start rounded-0 w-100"
              to={{ pathname: "/catalog/detail", search: "?id=" + item.id, data: item }}
            >
              Ver más detalles
            </Link>
          </div>
        </div>
      </section>
    );
  }
}
