import React, { Component } from "react";
import ItemGrid from './ItemGrid';

export default class GridItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
  render() {
    const { items } = this.props;
    return (
      <section className="GridItems">
        <div className="container">
          <div className="row">
            {
              items.map((item, index) => {
                return (
                  <div key={index} className="col-lg-4 col-md-6 mb-5 align-items-stretch">
                    <ItemGrid item={item} />
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
    );
  }
}
