import React, { Component } from "react";
class Footer extends Component {
  render() {
    return (
      <footer className="footer-area bg-fffcf4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget">
                <h3>Información</h3>
                <ul className="contact-info">
                  <li>
                    <a
                      href="#home"
                    >
                      Forestal Yeneco Limitada
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:contacto@yeneco.cl"
                    >
                      contacto@yeneco.cl
                    </a>
                  </li>
                  <li>
                    <a
                      href="tel:+56940870001"
                    >
                      +56940870001
                    </a>
                  </li>
                  <li>
                    <a
                      href="#home"
                    >
                      www.yeneco.cl
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              {
                /**
              <div className="single-footer-widget pl-3">
                <h3>REDES SOCIALES</h3>
                <ul className="contact-info">
                  <li>
                    <a
                      href=""
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i> Linkedin
                    </a>
                  </li>
                </ul>
              </div>
               */
              }
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget pl-3">
                <h3>ENLACES RÁPIDOS</h3>
                <ul className="contact-info">
                  <li>
                    <a
                      onClick={this.toggleNavbar}
                      offset="100"
                      href="/"
                      rel="noopener noreferrer"
                    >
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      href="/about"
                      rel="noopener noreferrer"
                    >
                      Nosotros
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      href="/services"
                      rel="noopener noreferrer"
                    >
                      Servicios
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      href="/catalog/list"
                      rel="noopener noreferrer"
                    >
                      Propiedades
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      href="/contact"
                      rel="noopener noreferrer"
                    >
                      Contacto
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p>
                  <i className="far fa-copyright"></i> 2023{" "}
                  <a href="/" rel="noopener noreferrer">Forestal Yeneco Ltda</a>. Todos los derechos
                  reservado
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <p style={{ textAlign: "center" }}>
                  Sitio desarrollado por{" "}
                  <a
                    href="https://www.neokode.cl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Neokode
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="map2">
          <img src={require("../../images/map2.png")} alt="map" />
        </div>
      </footer>
    );
  }
}

export default Footer;
