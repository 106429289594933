import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './sidebar.css';


export default class AdminSidebar extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoading: false,
      templates: [],
      refresh: false,
      open: false,
      style: "page-wrapper chiller-theme"
    };
  }

  handleOpen = (evt) => {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <div className={this.props.style}>
        <ul className={this.props.style} id="accordionSidebar">
          <div className="sidebar-brand d-flex align-items-center justify-content-center">
            <div className="sidebar-brand-text mx-3">Admin App</div>
            <div className="text-center d-none d-md-inline">
              <button className="rounded-circle border-0" id="sidebarToggle" onClick={this.props.changeStyle}></button>
            </div>
          </div>
          {/* <hr className="sidebar-divider my-0" />
          <li className="nav-item active">
            <Link className="nav-link" to="/admin/dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </Link>
          </li> */}
          <hr className="sidebar-divider" />
          <div className="sidebar-heading">
            Template
          </div>
          <li className="nav-item">
            <Link to="/admin/alltemplate" className="nav-link collapsed"><i className="fas fa-folder-plus"></i><span>Todos los template</span></Link>
            <Link to="/admin/createtemplate" className="nav-link collapsed"><i className="fas fa-folder-plus"></i><span>Crear template</span></Link>
          </li>
          <hr className="sidebar-divider" />
          <div className="sidebar-heading">
            Productos
          </div>
          <li className="nav-item">
            <Link to="/admin/allproduct" className="nav-link collapsed"><i className="fas fa-list"></i><span>Todos los producto</span></Link>
            <Link to="/admin/createproduct" className="nav-link collapsed"><i className="fas fa-plus"></i><span>Crear producto</span></Link>
            {/* <Link className="nav-link collapsed"><i className="fas fa-edit"></i><span>Editar producto</span></Link> */}
          </li>
          {/* <hr className="sidebar-divider" />
          <div className="sidebar-heading">
            Categorías
          </div>
          <li className="nav-item">
            <Link to="/admin/category" className="nav-link collapsed"><i className="far fa-calendar-plus"></i><span>Categorías</span></Link>
          </li> */}
          {/* <hr className="sidebar-divider" />
          <div className="sidebar-heading">
            Marca y modelo
          </div>
          <li className="nav-item">
            <Link to="/admin/brand" className="nav-link collapsed"><i className="fas fa-copyright"></i><span>Marca</span></Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/model" className="nav-link collapsed"><i className="fas fa-tshirt"></i><span>Modelo</span></Link>
          </li> */}
          {/* <hr className="sidebar-divider" />
                    <div className="sidebar-heading">
                        Configuración
                    </div>
                    <li className="nav-item">
                        <Link to="/admin/configuration" className="nav-link collapsed"><i className="fas fa-wrench"></i><span>General</span></Link>
                    </li> */}
        </ul>
      </div>

    );
  }
}
