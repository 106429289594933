import React, { Component } from "react";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { invokeApig } from "../../libs/awsLib";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

export default class ListTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      items: [],
      lastId: null,
      isMoreLoading: false,
      refresh: this.props.refresh,
      rowId: {},
      status: ""
    };
  }
  componentDidMount() {
    this.items();
  }
  componentWillReceiveProps(props) {
    if (props.refresh !== this.state.refresh) {
      this.items();
      this.setState({ refresh: props.refresh });
    }
  }
  items = async (request) => {
    this.setState({ isLoading: true, items: [] });
    try {
      const response = await this.getTemplates();
      this.setState({ items: response.items, lastId: response.lastId, status: response.status });
    } catch (e) {
      this.setState({ isError: true });
    }
    this.setState({ isLoading: false });
  };
  getTemplates() {
    return invokeApig({
      path: "/catalog/template/find",
      method: "POST",
      body: {},
    });
  }
  handleMore = async () => {
    this.setState({ isMoreLoading: true });
    try {
      const response = await this.getItems({ lastId: this.state.lastId });
      this.setState({
        items: [...this.state.items, ...response.items],
        lastId: response.lastId,
        isMoreLoading: false,
      });
    } catch (e) {
      this.setState({ isError: true, isMoreLoading: false });
    }
  };

  render() {
    const rowStyle = { cursor: 'pointer' };
    const data = this.state.items;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "id",
        isKey: true,
        text: "Código",
        hidden: true,
        formatter: (cellContent, row) => {
          return (
            <Link
              to={{ pathname: "/admin/edittemplate", search: "?id=" + row.id }}
            >
              {row.id}
            </Link>
          );
        }
      },
      {
        dataField: "name",
        text: "Nombre",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <Link
              to={{ pathname: "/admin/edittemplate", search: "?id=" + row.id }}
            >
              {row.name}
            </Link>
          );
        }
      },
      {
        dataField: "creation_date",
        text: "Fecha de creación",
        sort: true,
        formatter: (cell) => {
          const date = moment(cell);
          return date.format("DD-MM-YYYY HH:mm (UTC)");
        },
      },
    ];
    return (
      <div className="admin-table px-5 py-2 mx-auto">
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {
            props => (
              <div>
                <h5>Filtro: <SearchBar {...props.searchProps} /></h5>

                <hr />
                <BootstrapTable classes="table-responsive-md" {...props.baseProps} keyField="id" rowStyle={rowStyle} noDataIndication={this.state.isLoading ?
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div> : data.length === 0 ? <h3>No hay datos que mostrar</h3> : <></>
                } />
                {this.state.lastId &&
                  (this.state.isMoreLoading ? (
                    <div className="">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={this.handleMore}
                        className="btn btn-primary mt-auto align-self-start rounded-0"
                      >
                        Cargar más...
                      </button>
                    </div>
                  ))}
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }
}
