const dev = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.labs.yeneco.cl"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.labs.yeneco.cl"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_C5LD3rwuX",
    APP_CLIENT_ID: "3199rtl3o8ulg8ae942gq55snp",
    IDENTITY_POOL_ID: "us-west-2:438bf6ff-93a8-4106-a6ad-f009809ec417"
  },
  ReCAPTCHA: ''
};

const prod = {
  ga_analytics: '358125600',
  s3: {
    BUCKET: "files.yeneco.cl"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.yeneco.cl"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_517cmsASj",
    APP_CLIENT_ID: "6q1s11ncsr7k7nth4b3l8qc609",
    IDENTITY_POOL_ID: "us-east-1:723bd9c5-acdd-4ac2-b943-cc867024ac74"
  },
  ReCAPTCHA: '6LcLe-MkAAAAADctwwRAM1dHDPXf1DlIYnHeGbil'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : dev;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
