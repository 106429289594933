import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import ItemDetails from '../components/Catalog/ItemDetail';
import Footer from '../components/Layouts/Footer';

class ItemDetail extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <ItemDetails {...this.props} />
        <Footer />
      </React.Fragment>
    );
  }
}

export default ItemDetail;
