import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import Footer from '../components/Layouts/Footer';
import ContactForm from '../components/Home/Contact';

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <ContactForm />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Contact;
