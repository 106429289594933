import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import ReactGA from 'react-ga'
import { authUser, signOutUser } from "./libs/awsLib";
import { Preloader, Placeholder } from 'react-preloading-screen';
import Loader from './components/Shared/Loader';
import GoTop from './components/Shared/GoTop';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/fontawesome.min.css';
import './assets/css/style.scss';
import './assets/css/responsive.scss';
import WhatsApp from "./components/Shared/WhatsApp";
import config from "./config";
// import '../src/containers/admin/Layout/AdminSidebar.css'

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      showAlertError: false,
      alertMessage: '',
      profile: null,
      fullname: '',
      expanded: false
    };
  }

  async componentDidMount() {
    !!config.ga_analytics &&  ReactGA.pageview(window.location.pathname);
    try {
      if (await authUser()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      console.log(e);
      this.handleAlertError('Usuario no autenticado');
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated, fullname) => {
    this.setState({ isAuthenticated: authenticated, fullname: fullname });
  }
  handleLogout = event => {
    event.preventDefault();
    signOutUser();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }
  handleAlertError = (message) => {
    this.setState({ alertMessage: message, showAlertError: true });
  }

  handleDismissError = () => {
    this.setState({ showAlertError: false });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      <div className="App">
        <Preloader>
          <Routes childProps={childProps} />
          <GoTop scrollStepInPx="50" delayInMs="16.66" />
          <WhatsApp/>
          <Placeholder>
            <Loader />
          </Placeholder>
        </Preloader>
      </div>
    );
  }
}

export default withRouter(App);
