import React, { Component } from "react";
import { invokePublicApig } from "../../libs/awsLib";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OwlCarousel from "react-owl-carousel3";
import "../Home/HomeCatalog.css";
import ItemGrid from "../Catalog/ItemGrid";
library.add(fas);

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='fas fa-angle-left'></i>",
    "<i class='fas fa-angle-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1200: {
      items: 2,
    },
  },
};

class HomeCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      display: false,
      items: [],
    };
  }
  componentDidMount = async () => {
    this.setState({ isLoading: true, display: true });
    const response = await this.getItems()
    if (response.status === 0) {
      this.setState({ items: response.items, isLoading: false });
    }
    else {
      this.setState({
        errorMessage:
          "Error al obtener propiedades. Favor intenta más tarde.",
        isLoading: false,
      });
    }
  };
  getItems() {
    return invokePublicApig({
      path: "/catalog/find",
      method: "POST",
      body: {
        featured: true,
      },
    });
  }

  render() {
    const { items } = this.state;
    return (
      <section id="catalog" className="ptb-100">
        <div className="container">
          <div className="row justify-content-center pb-5">
            <div className="col-md-7 default-section-title">
              <h2>Destacados</h2>
              <div className="bar"></div>
            </div>
          </div>
          <div className="row justify-content-center text-center pb-5 mb-3">
            {items.length === 0 ? (
              <div className="d-flex justify-content-center">
                <div className="notData">
                  <h2>No hay datos que mostrar</h2>
                </div>
              </div>
            ) : items.length === 1 || items.length === 2 ?
              items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-4 col-md-4 mb-5 align-items-stretch"
                  >
                    <ItemGrid item={item} />
                  </div>
                );
              }) 
              :
              <OwlCarousel
                className="services-slides owl-carousel owl-theme"
                {...options}
              >
                {items.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-lg-12 col-md-12 mb-5 align-items-stretch"
                    >
                      <ItemGrid item={item} />
                    </div>
                  );
                })}
              </OwlCarousel>}
          </div>
        </div>
      </section>
    );
  }
}

export default HomeCatalog;
