import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      item: this.props.item,
    };
  }
  render() {
    const { item } = this.state;
    return (
      <section className="ItemList">
        <div className="container">
          <div className="row">
            <div className="col-xs-18 col-md-4">
              <div
                className="item-img-list card-img-top"
                style={{
                  backgroundImage: `url(/images/catalog/${item.preview.image})`,
                }}
              >
                {/* <div className="label">{item.attributes.type.value}</div> */}
              </div>
            </div>
            <div className="card-body d-flex flex-column col-xs-18 col-md-8 previewContainer">
              <h3>{item.title}</h3>
              <span className="mb-3"><b>Código:</b> {item.code}</span>
              <Link
                className="btn btn-primary mt-auto align-self-start rounded-0 w-100"
                to={{ pathname: "/catalog/detail", search: "?id=" + item.id, data: item }}
              >
                Ver más detalles
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
