import React, { Component } from "react";
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import ImageGallery from "react-image-gallery";
import './RenderGallery.css';

function transform(node, index) {
  if (node.type === 'tag' && node.name === 'iframe') {
    node.attribs.width = '690';
    node.attribs.height = '400';
    return convertNodeToElement(node, index, transform);
  }
}

export default class RenderGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      showVideo: {},
      images: []
    };

    if (this.props.data.images.length) {
      this.props.data.images.map((image, index) => {
        this.state.images.push({
          original:
            "/images/catalog/" +
            this.props.data.id +
            "/" +
            image,
          thumbnail:
            "/images/catalog/" +
            this.props.data.id +
            "/" +
            image,
        });
        return {};
      });
    } else {
      this.state.images.push({
        original: "/images/no_image.jpg",
        thumbnail: "/images/no_image.jpg",
      });
    }
    if (this.props.data.attributes) {
      this.props.data.attributes.map((value, index) => {
        if (value.type === "video" && value.value !== null) {
          this.state.images.push({
            name: value.name,
            code: value.value,
            description: 'Render custom slides (such as videos)',
            renderItem: this._renderVideo.bind(this)
          });
        };
        return '';
      })
    }
    else {
      this.state.images.push({
        original: "/images/no_image.jpg",
        thumbnail: "/images/no_image.jpg",
      });
    };

  }

  _onSlide(index) {
    this._resetVideo();
  }

  _onPause(index) {
  }

  _onPlay(index) {
  }

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(name) {
    
    const showVideo = this.state;
    this.setState({
      showVideo: {
        ...showVideo,
        [name]: !showVideo[name]
      }
    });
    if (!showVideo[name]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderVideo(item) {
    const options = {
      transform: transform
    }
    return (
      <div className="video-wrapper">
        {
          this.state.showVideo[item.name] ?
            ReactHtmlParser(item.code, options)
          :
            <div className="hand-click" onClick={this._toggleShowVideo.bind(this, item.name)}>
              <div className='play-button'></div>
              {ReactHtmlParser(item.code, options)}
            </div>
        }
      </div>
    );
  }

  render() {
    return (
        <ImageGallery
          items={this.state.images}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause}
          onPlay={this._onPlay}
          infinite={true}
          showBullets={true}
          showFullscreenButton={this.state.showFullscreenButton}
          showPlayButton={this.state.showPlayButton}
          showThumbnails={false}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
        />
    );
  }
}
