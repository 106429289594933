import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from './pages/Home';
import Catalog from './pages/Catalog'
import ItemDetail from './pages/ItemDetail'
import AboutDetails from './pages/AboutDetails'
import Services from './pages/Services'
import NotFound from './pages/NotFound';

import Login from './containers/admin/Login';
import Contact from "./pages/Contact";
import AllProducts from "./pages/Admin/AllProducts";
import AllTemplate from "./pages/Admin/AllTemplate";
import CreateProduct from "./pages/Admin/CreateProduct";
import EditProduct from "./pages/Admin/EditProduct";
import CreateTemplate from "./pages/Admin/CreateTemplate";
// import EditTemplate from "./pages/Admin/EditTemplate";
import EditTemplate from "./pages/Admin/EditTemplate";
import Category from "./pages/Admin/Category";
import Brand from "./pages/Admin/Brand";
import Model from "./pages/Admin/Model";

export default ({ childProps }) =>

  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/catalog/list" exact component={Catalog} props={childProps} />
    <AppliedRoute path="/catalog/detail" exact component={ItemDetail} props={childProps} />
    <AppliedRoute path="/about" exact component={AboutDetails} props={childProps} />
    <AppliedRoute path="/services" exact component={Services} props={childProps} />
    <AppliedRoute path="/contact" exact component={Contact} props={childProps} />
    {/* <AppliedRoute path="/" exact component={ComingSoon} props={childProps} /> */}

    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    {/* <AuthenticatedRoute path="/admin" exact component={Admin} props={childProps} /> */}
    <AuthenticatedRoute path="/admin" exact component={AllProducts} props={childProps} />
    {/* <AuthenticatedRoute path="/admin/dashboard" exact component={Dashboard} props={childProps} /> */}
    <AuthenticatedRoute path="/admin/allproduct" exact component={AllProducts} props={childProps} />
    <AuthenticatedRoute path="/admin/alltemplate" exact component={AllTemplate} props={childProps} />
    {/* <AuthenticatedRoute path="/admin/createproduct" exact component={NewProduct} props={childProps} /> */}
    <AuthenticatedRoute path="/admin/createproduct" exact component={CreateProduct} props={childProps} />
    <AuthenticatedRoute path="/admin/editproduct" exact component={EditProduct} props={childProps} />
    <AuthenticatedRoute path="/admin/createtemplate" exact component={CreateTemplate} props={childProps} />
    <AuthenticatedRoute path="/admin/edittemplate" exact component={EditTemplate} props={childProps} />
    <AuthenticatedRoute path="/admin/category" exact component={Category} props={childProps} />
    <AuthenticatedRoute path="/admin/brand" exact component={Brand} props={childProps} />
    <AuthenticatedRoute path="/admin/model" exact component={Model} props={childProps} />
    {/* <AuthenticatedRoute path="/admin/detail" exact component={AdminItem} props={childProps} /> */}
    <Route component={NotFound} />
  </Switch>;





