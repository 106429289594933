import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import Footer from '../components/Layouts/Footer';
import About from '../components/Home/About';
import HomeCatalog from '../components/Home/HomeCatalog';
import Contact from '../components/Home/Contact';
import Services from '../components/Home/Services';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <HomeCatalog />
        <div className="separator-area"></div>
        <About />
        <div className="separator-area"></div>
        <Services />
        <div className="separator-area"></div>
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Home;
