import React, { Component } from "react";
import config from '../../config';
import ReCAPTCHA from "react-google-recaptcha";
import "../Home/About.css";
export default class FormContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      name: '',
      phone: '',
      email: '',
      subject: "",
      key: '',
      message: '',
      alertMessage: '',
      isErrorMessage: false,
      isErrorSubject: false,
      isErrorName: false,
      isErrorEmail: false,
      isErrorPhone: false,
    };
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  validate() {
    var errors = {};
    errors.isErrorName = this.state.name.length < 3;
    errors.isErrorEmail = this.state.email.length < 8;
    errors.isErrorPhone = this.state.phone.length < 8;
    errors.isErrorSubject = this.state.message.length < 3;
    errors.isErrorMessage = this.state.message.length < 3;
    errors.hasError = errors.isErrorName || errors.isErrorEmail || errors.isErrorPhone || errors.isErrorSubject || errors.isErrorMessage;
    return errors;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.hasError) {
      errors.hasError = undefined;
      this.setState({ ...errors });
      return;
    }
    this.setState({ ...errors, isLoading: true });
    try {
      this.contact({
        code: this.props.data ? this.props.data.code : undefined,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
        key: this.state.key,
      });
    }
    catch (e) {
      console.error(e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  };

  handleClose = (event) => {
    this.setState({ isErrorMessage: false, isSuccess: false });
  }

  contact(data) {
    return fetch(config.apiGateway.URL + "/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.code === 0) {
          this.setState({ isLoading: false, isSuccess: true, alertMessage: 'Hemos recibido tu mensaje, pronto nos comunicaremos contigo.', key: "", isErrorMessage: false, name: '', email: '', phone: '', message: '', subject: '' });
        } else {
          this.setState({ isLoading: false, isSuccess: false, alertMessage: 'Error al enviar mensaje. Favor intenta nuevamente', isErrorMessage: true });
        }
      });
  }

  handleChangeRC = (value) => {
    this.setState({ key: value, isEmailError: false, isKeyError: false });
  };

  render() {
    return (
      <form className="contact_form" onSubmit={this.handleSubmit}>
        <div className="col-md-12">
          <div className="form-group">
            <label className="h6 small d-block text-uppercase">
              Nombre Completo
              <span className="text-danger">*</span>
            </label>
            <input
              className={"form-control"}
              name="name"
              id="name"
              required={true}
              data-error="Ingresa tu nombre"
              placeholder="Juan Rodriguez"
              type="text"
              onChange={this.handleChange}
              value={this.state.name}
            />
            <div className="help-block with-errors">
              {
                this.state.isErrorName &&
                <div className="invalid-feedback">
                  Debes ingresar tu nombre
                </div>
              }
            </div>
          </div>
          <div className="form-group">
            <label className="h6 small d-block text-uppercase">
              Correo electronico
              <span className="text-danger">*</span>
            </label>
            <input
              className={"form-control"}
              name="email"
              id="email"
              required={true}
              data-error="Ingresa tu email"
              placeholder="juan@gmail.com"
              type="email"
              onChange={this.handleChange}
              value={this.state.email}
            />
            <div className="help-block with-errors">
              {
                this.state.isErrorEmail &&
                <div className="invalid-feedback">
                  Debes ingresar tu email
                </div>
              }
            </div>
          </div>
          <div className="form-group">
            <label className="h6 small d-block text-uppercase">
              Teléfono
              <span className="text-danger">*</span>
            </label>
            <input
              className={"form-control"}
              id="phone"
              name="phone"
              data-error="Ingresa tu teléfono"
              required={true}
              placeholder="12345678"
              type="text"
              onChange={this.handleChange}
              value={this.state.phone}
            />
            <div className="help-block with-errors">
              {
                this.state.isErrorPhone &&
                <div className="invalid-feedback">
                  Debes ingresar tu teléfono
                </div>
              }
            </div>
          </div>
          <div className="form-group">
            <label className="h6 small d-block text-uppercase">
              Asunto
              <span className="text-danger">*</span>
            </label>
            <input
              className={"form-control"}
              id="subject"
              name="subject"
              data-error="Ingresa un asunto"
              required={true}
              placeholder="Consulta"
              type="text"
              onChange={this.handleChange}
              value={this.state.subject}
            />
            <div className="help-block with-errors">
              {
                this.state.isErrorSubject &&
                <div className="invalid-feedback">
                  Debes ingresar un asunto
                </div>
              }
            </div>
          </div>
          <div className="form-group">
            <label className="h6 small d-block text-uppercase">
              Mensaje
              <span className="text-danger">*</span>
            </label>
            <textarea
              className={"form-control"}
              rows="4"
              name="message"
              required={true}
              data-error="Escribe el mensaje"
              id="message"
              onChange={this.handleChange}
              value={this.state.message}
              placeholder="Hola me gustaria saber ..."
            />
            <div className="help-block with-errors">
              {
                this.state.isErrorMessage &&
                <div className="invalid-feedback">
                  Debes ingresar un mensaje
                </div>
              }
            </div>
          </div>
          {
            !!config.ReCAPTCHA &&
            <div className="mb-2 row justify-content-center">
              <ReCAPTCHA
                sitekey={config.ReCAPTCHA}
                onChange={this.handleChangeRC}
                className="recaptcha"
              />
              <div className="help-block with-errors">
                {this.state.isErrorCaptcha && (
                  <div className="invalid-feedback">
                    Debes completar captcha
                  </div>
                )}
              </div>
            </div>
          }
          {
            (this.state.isErrorMessage || this.state.isSuccess) &&
            <div className={"alert alert-" + (this.state.isErrorMessage ? "danger" : "success") + " col-lg-12 col-md-12"} role="alert">
              {this.state.isErrorMessage ? "Error al enviar el mensaje, por favor intenta nuevamente." : "Mensaje enviado exitosamente. Nos comunicaremos contigo prontamente."}
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
          <div className="form-group">
            <button type="submit" className="btn btn-primary rounded-0 py-2 px-5 w-100" disabled={this.state.isLoading}>
              {
                this.state.isLoading ?
                  "Enviando..."
                  :
                  "Enviar Mensaje"
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
};