import React, { Component } from "react";

export default class CatalogDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      type: this.props.type ? this.props.type : "grid",
    };
  }
  handleGridClick = (evt) => {
    this.setState({ type: "grid" });
    if (this.props.onChange) {
      this.props.onChange("grid");
    }
  };
  handleListClick = (evt) => {
    this.setState({ type: "list" });
    if (this.props.onChange) {
      this.props.onChange("list");
    }
  };
  handleChange = (sel) => {
    if (this.props.onChangeSort) {
      this.props.onChangeSort(sel.target.value);
    }
  };
  render() {
    return (
      <section className="section-display">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="catalogjumbotron">
                <div className="container">
                  <div className="filter-title">
                    <span>Ordenar</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="form-group col-xs-6">
                      <select className="form-control" onChange={this.handleChange}>
                        <option value="" disabled hidden>
                          Selecciona
                        </option>
                        <option value="">
                          Precio de menor a mayor
                        </option>
                        <option value="">
                          Precio de mayor a menor
                        </option>
                        <option value="">Más antiguas</option>
                        <option value="">Más nuevas</option>
                      </select>
                    </div>
                    <div className="form-group col-xs-6">
                      <button className={this.state.type === 'grid' ? "btn btn-primary active rounded-0 px-3 mx-2" : "btn btn-primary rounded-0 px-3 mx-2"} onClick={this.handleGridClick}><i className="fas fa-th-large"></i></button>
                      <button className={this.state.type === 'list' ? "btn btn-primary active rounded-0 px-3 mx-2" : "btn btn-primary rounded-0 px-3 mx-2"} onClick={this.handleListClick}><i className="fas fa-list"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
