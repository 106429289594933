import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import CatalogList from '../components/Catalog/Catalog';
import Footer from '../components/Layouts/Footer';

class Catalog extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <CatalogList {...this.props}/>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Catalog;
