import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

class Services extends Component {
  state = { display: false };

  componentDidMount() {
    this.setState({ display: true });
  }

  render() {
    return (
      <section id="services" className="ptb-100">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 default-section-title">
              <h2>Servicios</h2>
              <div className="bar"></div>
            </div>
          </div>
          <div className="row d-flex no-gutters">
            <div className="col-md-6 d-flex">
              <div className="img-method d-flex align-self-stretch align-items-center justify-content-center justify-content-md-end mb-4 mb-sm-0">
              </div>
            </div>
            <div className="col-md-6 pl-md-5 py-md-5">
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-shopping-cart"></span>
                </div>
                <div className="text pl-4 pb-3">
                  <h4>Compra y Venta de Bosques</h4>
                  <p>
                    No encargamos de buscar un comprador para tu bosque ubicado en la región del Bío Bío.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-tree"></span>
                </div>
                <div className="text pl-4 pb-3">
                  <h4>Compra de Pino y Eucaliptus</h4>
                  <p>
                    Compramos tu madera bajo las distintas modalidades existentes en el mercado.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-chart-bar"></span>
                </div>
                <div className="text pl-4 pb-3">
                  <h4>Tasaciones</h4>
                  <p>
                    Todo lo que necesitas saber de tu bosque a través de la evaluación de expertos.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-suitcase"></span>
                </div>
                <div className="text pl-4 pb-3">
                  <h4>Asesoría Legal</h4>
                  <p>
                    Consideramos todos los aspectos legales para una transacción segura.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
