import React, { Component } from "react";
import "../Home/About.css";
class AboutDetails extends Component {
  render() {
    return (
      <section className="about p-5">
        <div class="container py-3">
          <div class="mb-5">
            <div class="row ">
              <div class="col-md-6 px-3 order-lg-0 order-md-0 order-sm-1 order-0">
                <div class="px-3">
                  <div className="site-section-title">
                    <h4>NOSOTROS</h4>
                  </div>
                  <p class="">
                    Somos un grupo de profesionales interdisciplinarios con más de 5
                    años de experiencia en el mundo de la administración de
                    condominios, en la actualidad hemos desarrollado esta empresa
                    con el propósito de dar a con nuestros clientes, el acceso a las
                    inversiones inmobiliarias a través de la educación financiera y
                    apoyo integral en todo el proceso de una venta, compra o
                    arriendo de algún bien raíz.
                  </p>
                </div>
              </div>
              <div class="col-md-5 mt-3 mb-5 order-lg-1 order-md-1 order-sm-0 order-1">
                <img src={require("../../images/about.jpg")} alt="logo" />
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-6 px-3 mb-5">
              <div class="px-3">
                <div className="site-section-title">
                  <h4>MISIÓN</h4>
                </div>
                <p>
                  Es asesorar de manera integral a nuestros clientes en la venta,
                  compra o arriendo de bienes raíces dándole una garantía efectiva
                  en la realización de estos procesos, para una eficaz realización
                  del negocio.
                </p>
              </div>
            </div>
            <div class="col-md-6 px-3">
              <div class="px-3">
                <div className="site-section-title">
                  <h4>NUESTRO EQUIPO</h4>
                </div>
                <p>
                  Está conformado por abogados expertos en el área del derecho
                  inmobiliario y Cobranzas en general, personal con experiencia en
                  Corretajes y Administración de Edificios Residenciales y de
                  Oficinas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
}

export default AboutDetails;
