import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = {
    collapsed: true,
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);

    this.menuActiveClass();
  }

  menuActiveClass = () => {
    let mainNavLinks = document.querySelectorAll("nav ul li a");
    window.addEventListener("scroll", () => {
      let fromTop = window.scrollY;
      mainNavLinks.forEach((link) => {
        if (link.hash) {
          let section = document.querySelector(link.hash);

          if (
            section &&
            section.offsetTop <= fromTop &&
            section.offsetTop + section.offsetHeight > fromTop
          ) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        }
      });
    });
  };

  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "navbar-collapse collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <React.Fragment>
        <div id="home" className="navbar-one">
          <div className="navbar-top">
            <div className="container py-3">
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="d-flex mr-auto justify-content-center">
                    <a href="mailto:contacto@yeneco.cl" className="d-flex align-items-center mr-4">
                      <i className="fas fa-envelope mr-2"></i>
                      <span className="d-none d-md-inline-block">
                        contacto@yeneco.cl
                      </span>
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=%2B56940870001&.&app_absent=0" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center mr-4">
                      <i className="fab fa-whatsapp mr-2"></i>
                      <span className="d-none d-md-inline-block">
                        +56940870001
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-6 text-right">
                  {/**
                  <div className="d-flex mr-auto justify-content-center">
                    <a href="" className="p-2 pl-0" rel="noopener noreferrer">
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a href="" className="p-2 pl-0" rel="noopener noreferrer">
                      <i className="fab fa-youtube"></i>
                    </a>
                    <a href="" className="p-2 pl-0" rel="noopener noreferrer">
                      <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="" className="p-2 pl-0" rel="noopener noreferrer">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
          <nav
            id="navbar"
            className="navbar navbar-expand-lg navbar-style-one navbar-light bg-light"
          >
            <div className="container">
              <Link
                onClick={this.toggleNavbar}
                offset="100"
                className="navbar-brand"
                to="/"
              >
                <img src={require("../../images/logo.png")} alt="logo" />
                <img src={require("../../images/logo.png")} alt="logo" />
              </Link>
              <button
                onClick={this.toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link
                      onClick={this.toggleNavbar}
                      offset="0"
                      className="nav-link"
                      rel="noopener noreferrer"
                      to="/catalog/list"
                      href="#catalog"
                    >
                      Propiedades
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      className="nav-link"
                      rel="noopener noreferrer"
                      href="/#about"
                    >
                      Nosotros
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      className="nav-link"
                      rel="noopener noreferrer"
                      href="/#services"
                    >
                      Servicios
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={this.toggleNavbar}
                      offset="0"
                      className="nav-link"
                      rel="noopener noreferrer"
                      href="/#contact"
                    >
                      Contacto
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

export default Navbar;
