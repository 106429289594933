import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import AboutDetail from '../components/Home/AboutDetails';
import Footer from '../components/Layouts/Footer';

class AboutDetails extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <AboutDetail />
        <Footer />
      </React.Fragment>
    );
  }
}

export default AboutDetails;
