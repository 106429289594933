import React, { Component } from "react";
import CKEditor from "react-ckeditor-component";

export default class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      list: [{ atributo: "", label: "", type: "" }],
      typeList: [{ clave: "", valor: "" }],
      type: [
        { id: "text", name: "Texto" },
        { id: "number", name: "Numerico" },
        { id: "select", name: "Selección" },
        { id: "color", name: "Color" },
        { id: "email", name: "Correo" },
        { id: "tel", name: "Teléfono" },
        { id: "url", name: "Link" },
      ],
      typeId: "",
    };
  }
  handleChangeType = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleChange(i, e) {
    let list = this.state.list;
    list[i][e.target.name] = e.target.value;
    this.setState({ list });
  }

  addFormFields() {
    this.setState({
      list: [...this.state.list, { atributo: "", label: "", type: "" }],
    });
  }

  removeFormFields(i) {
    let list = this.state.list;
    list.splice(i, 1);
    this.setState({ list });
  }

  render() {
    const config = {
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        {
          name: "editing",
          groups: ["find", "selection", "spellchecker", "editing"]
        },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
          name: "paragraph",
          groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
        },
        "/",
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        "/",
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] }
      ],
      removeButtons:
        "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
      fontSize_sizes: "16/16px;24/24px;48/48px;",
      font_names:
        "Arial/Arial, Helvetica, sans-serif;" +
        "Times New Roman/Times New Roman, Times, serif;" +
        "Verdana",
      allowedContent: true
      // disableNativeSpellChecker: false
      // skin: "moono",
      // plugins:
      //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
    };
    return (
      <div className="category">
        <div className="container-fluid">
          <div class="card shadow-sm">
            <div class="card-body p-5">
              <div class="row">
                <div class="col-md-12 col-lg-5">
                  <form>
                    <div class="card shadow-sm mb-4">
                      <div class="card-header text-left p-2">
                        <label className="m-0">Nombre</label>
                      </div>
                      <div class="card-body p-1">
                        <div className="form-group-admin">
                          <input type="text" required={true} value={this.state.id} placeholder="Escriba aquí" onChange={this.handleChange} className="form-control" id="id" />
                        </div>
                      </div>
                    </div>
                    <div class="card shadow-sm mb-4">
                      <div class="card-header text-left p-2">
                        <label className="m-0">Descripción corta</label>
                      </div>
                      <div class="card-body p-1">
                        <div className="form-group-admin">
                          <CKEditor
                            id="long_desc"
                            config={config}

                            activeclassName="p10"
                            content={this.state.long_desc}
                            events={{
                              "required": true,
                              "change": this.handleChangeHtml
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="form-group-admin col-md-12 mb-4">
                        <button
                          className="btn btn-success mt-auto align-self-start rounded-0 px-5 mb-5 w-100"
                          type="button"
                        >
                          Crear Categoría
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-12 col-lg-7">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th class="text-end">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td><b>Chalecos</b></td>
                        <td>Categoría de chalecos</td>
                        <td class="text-end">
                          <div class="dropdown">
                            ...
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
