import React, { Component } from "react";
import CatalogDisplay from "./CatalogDisplay";
import ListItems from "./ListItems";
import GridItems from "./GridItems";
import { invokePublicApig } from "../../libs/awsLib";

export default class Catalog extends Component {
  limit = 9;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      filterType: "grid",
      items: [],
      lastId: "",
      request: { limit: this.limit },
    };
  }
  componentDidMount() {
    this.updateItems({ featured: false }, true);
  }
  updateItems = async (request, newSearch) => {
    newSearch
      ? this.setState({ isLoading: true, items: [] })
      : this.setState({ isLoading: true });
    await this.getItems(request).then(
      (response) => {
        const lastId = request.featured
          ? ""
          : response.items.length <= this.limit
            ? response.lastId
            : "";
        if (request.lastId) {
          const items = [...this.state.items, ...response.items];
          this.setState({ items: items, lastId: lastId, isLoading: false });
        } else {
          this.setState({
            items: response.items,
            lastId: lastId,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({
          errorMessage: "Error al obtener datos. Favor intenta más tarde.",
          isLoading: false,
        });
      }
    );
  };
  getItems(request) {
    return invokePublicApig({
      path: "/catalog/find",
      method: "POST",
      body: request,
    });
  }

  handleTypeChange = (type) => {
    this.setState({ filterType: type });
  };
  handleSearch = (filter) => {
    const request = { ...this.state.request, ...filter };
    request.lastId = undefined;
    this.updateItems(request, true);
    this.setState({ request: { ...request } });
  };
  handleChangeSort = (sort) => {
    const { request } = this.state;
    request.sort = sort;
    request.lastId = undefined;
    this.updateItems(request, true);
  };
  handleLoadMore = () => {
    const { request } = this.state;
    request.lastId = this.state.lastId;
    this.updateItems(request, false);
  };
  render() {
    return (
      <section className="Catalog">
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-lg-2 col-md-4">
              <CatalogFilter onChange={this.handleSearch} />
            </div> */}
            <div className="col-lg-10 col-md-8">
              <CatalogDisplay
                onChange={this.handleTypeChange}
                onChangeSort={this.handleChangeSort}
              />

              {this.state.isLoading && !this.state.lastId ? (
                <div className="d-flex justify-content-center spinner">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Cargando...</span>
                  </div>
                </div>
              ) : this.state.items.length === 0 ? (
                <div className="notData">
                  <h2>No hay datos que mostrar</h2>
                </div>
              ) : this.state.filterType === "list" ? (
                <ListItems items={this.state.items} lastId={this.state.lastId} />
              ) : (
                <GridItems items={this.state.items} lastId={this.state.lastId} />
              )}
              {
                !this.state.isLoading && this.state.lastId && <button onClick={this.handleLoadMore}>Cargar m&aacute;s...</button>
              }
              {
                this.state.isLoading && this.state.lastId && <h3>Cargando...</h3>
              }</div>
          </div>
        </div>
      </section>
    );
  }
}
