import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import format from "format-number";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { invokePublicApig } from '../../libs/awsLib';
// import ContactForm from '../contact/ContactForm';
import "react-image-gallery/styles/css/image-gallery.css";
import "./ItemDetail.css";
import RenderGallery from "./RenderGallery";
import FormContact from "../Home/FormContact";

export default class ItemDetail extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      ...this.props.location.state,
      isLoading: true,
      notItem: false,
      attributesList: [],
      defaultImages: [],
      data: null,
      showVideo: {},
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
    };
  }
  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);
    const id = params.get('id');
    if (id) {
      this.readItem(id);
    }
  };

  readItem = async (id) => {
    const response = await this.getItems();
    response.items.forEach((item, index) => {
      if (item.id === id) {
        this.setState({ notItem: false, data: item, defaultImages: item.images.slice() });
      }
      else {
        this.setState({ notItem: true });
      }
    });
  }

  /*
  find item by unique id from db
  */
  getItems() {
    return invokePublicApig({
      path: "/catalog/find",
      method: "POST",
    });
  }

  renderValue(value) {
    switch(value.type) {
      case "number":
        return format()(value.value);
      case "select":
        return value.list.filter((el) => el.key === value.value)[0].value;
      default:
        return value.value;
    }
  }

  render() {
    const { data, notItem } = this.state;
    if (!data) {
      return (
        notItem ? <div className="ItemDetail error">
          <h2>No se encuentra el bosque</h2>
          <Link className="btn btn-primary mt-auto align-self-start rounded-0" to="/catalog/list">Ver más bosques</Link>
        </div> : <div className="ItemDetail error pt-5 text-center">
          <h2>Cargando datos, favor espere un momento...</h2>
          <h2 className="mt-5"><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div></h2>
        </div>
      );
    }

    return (
      <section className="ItemDetail">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="imageContainer">
                  <RenderGallery data={data}/>
              </div>
              <div className="item-caract">
                <div className="row mb-5 justify-content-center">
                  <div className="col-md-12 order-md-2">
                    <h3 className="text-left">{data.title}</h3>
                    <p>
                      <b>Descripci&oacute;n: </b> </p>
                    <span>
                      {ReactHtmlParser(data.long_desc)}
                    </span>
                  </div>
                </div>
              </div>
              {
                data.attributes.map((value, index) => {
                  if (value.type === "map" && value.value !== null) {
                    return (
                      <div key={index} className="item-caract">
                        <div className="row justify-content-center">
                          <div className="col-md-12">
                            <h3>Mapa</h3>
                            {ReactHtmlParser(value.value)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return '';
                })
              }
            </div>
            <div className="col-lg-4">
              <div className="contact-detail border rounded">
                <div className="col-md-12 mb-3 order-md-1">
                  <p >
                    <b>C&oacute;digo: </b>
                    <span>
                      {data.code}
                    </span>
                  </p>
                  {
                    data.attributes.map((value, index) => {
                      if (value.type !== "map" && value.type !== "video") {
                        return (
                          <p key={index}>
                            <b>{value.name}: </b>
                            { this.renderValue(value) }
                          </p>
                        );
                      }
                      return '';
                    })
                  }
                </div>
                <div className="col-md-6  mb-3">
                </div>
              </div>
              <div className="contact-detail border rounded mb-3">
                <div className="col-md-12">
                  <h3 className="h4 text-black widget-title mb-3 text-left">Contáctanos</h3>
                </div>
                <FormContact data={this.state.data}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
