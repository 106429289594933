export function validateNotEmpty(text) {
  return text && !/^\s*$/.test(text)
}
export function validateEmail(text) {
  return text && /^[a-zA-Z]+[a-zA-Z0-9.\-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(text);
}
export function validateNumber(text) {
  var re = new RegExp('^[0-9]+$');
  return text && re.test(text);
}
export function validateCode(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-Z]{' + ini + ',' + end + '}$');
  return text && re.test(text);
}
export function validateText(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-ZáéíóóÁÉÍÓÚ]{' + ini + ',' + end + '}$');
  return text && re.test(text);
}
export function validateTextNumber(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-Z0-9]{' + ini + ',' + end + '}$');
  return text && re.test(text);
}
export function validateTextAndSpace(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-ZáéíóúÁÉÍÓÚ ]{' + ini + ',' + end + '}$');
  return text && re.test(text);
}
export function validateTextNumberAndSpace(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-Z0-9áéíóúÁÉÍÓÚ ]{' + ini + ',' + end + '}$');
  return text && re.test(text);
}
export function validatePhone(text) {
  return text && /^[+]{1}[0-9]{3,16}$/.test(text);
}
export function validatePassword(text) {
  return text && /(?=.*[a-z])/.test(text) && /(?=.*[A-Z])/.test(text) && /(?=.*[0-9])/.test(text) && /(?=.[:;!@#$%^&*_])/.test(text) && /(?=.{8,})/.test(text);
}
