import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { authUser, signOutUser } from "../../../libs/awsLib";
import config from "../../../config";
// import './sidebar.css';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    !!config.ga_analytics &&  ReactGA.pageview(window.location.pathname);
    try {
      if (await authUser()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      
      this.handleAlertError("Usuario no autenticado");
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated, fullname) => {
    this.setState({ isAuthenticated: authenticated, fullname: fullname });
  };

  handleLogout = (event) => {
    event.preventDefault();
    signOutUser();

    this.userHasAuthenticated(false);
    window.location.href = "/admin";
  };

  render() {
    
    return (

      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

        {/*  <!-- Sidebar Toggle (Topbar) --> */}
        <button id="sidebarToggleTop" className="btn btn-link d-md-none border-0 mr-3" onClick={this.props.changeStyle1}>
          <i className="fa fa-bars"></i>
        </button>
        <p className="m-0 p-3">{this.props.dashboard}</p>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">Ir al sitio web</span>
            </Link>
          </li>
          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item dropdown no-arrow">
            <Link className="nav-link dropdown-toggle" onClick={this.handleLogout} to="/admin">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">LogOut</span>
              <i className="fas fa-sign-out-alt"></i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}
export default TopBar;
