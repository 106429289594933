import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import App from './App';
import unregisterServiceWorker from './serviceWorker';
import config from './config';

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Modal from 'react-modal';

export default createBrowserHistory({});

const hist = createBrowserHistory();

if (config.ga_analytics) {
  ReactGA.initialize(config.ga_analytics);
  hist.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname + location.search)
  });
}

Modal.setAppElement('body');

ReactDOM.render(
  <Router history={hist}>
    <App />
  </Router>,
  document.getElementById("root")
);

unregisterServiceWorker();
